import React from "react";
import Image from "next/image";
import styles from "./acheiver.module.css";
import pic1 from "../../assets/images/home/ISHITA KISHORE.webp";
import pic2 from "../../assets/images/home/YAKSH CHAUDHARY.webp";
import pic3 from "../../assets/images/home/Jagarthi Awasthi.webp";
import pic4 from "../../assets/images/home/Ganesh Kumar.webp";
import pic5 from "../../assets/images/home/Srushti Jayant.webp";
import pic6 from "../../assets/images/home/SHAURYA ARORA.webp";

import Link from "next/link";

const acheiverslist=[
    { Image: pic6 , alt: "SHAURYA ARORA", name: "SHAURYA ARORA", dept: "AIR 14", class:"img-fluid",cont: "Civiliasation-2023"},
    { Image: pic1 , alt: "ISHITA KISHORE", name: "ISHITA KISHORE", dept: "AIR 01", class:"img-fluid",cont: "Civiliasation-2022"},
    { Image: pic2 , alt: "YAKSH CHAUDHARY", name: "YAKSH CHAUDHARY", dept: "AIR 06", class:"img-fluid",cont: "Mainstorming-2021"},
    { Image: pic3 , alt: "JAGARTHI AWASTHI", name: "JAGARTHI AWASTHI", dept: "AIR 02", class:"img-fluid",cont: "Civiliasation-2020"},
    { Image: pic4, alt: "GANESH KUMAR BASKAR", name: "GANESH KUMAR", name1:"BASKAR", dept: "AIR 07", class:"img-fluid",cont: "Civiliasation-2019"},
    { Image: pic5 , alt: "SRUSHTI JAYANT DESHMUKH", name: "SRUSHTI JAYANT", name1:"DESHMUKH", dept: "AIR 05", class:"img-fluid",cont: "Civiliasation-2018"},
   
]
const acheiver=()=>{
    return(
        <>
        <div className="container">

        <div className="row">
          <div className="col pt-3">
            <div className={`${styles.scrollparent}`}>

                {acheiverslist.map((item,index)=>(
                    <div className={`${styles.scrolldiv} pe-lg-3 text-center pe-5`} key={index}>
                      <div style={{width: '142px', height: '165px'}} className="text-center"><Image src={item.Image} className={item.class} alt={item.alt} title={item.alt} /></div>                 
                    <p className={`${styles.acheivername} mt-2 mb-0 text-center`}>
                    {item.name}
                    </p>
                    <p className={`${styles.acheivername} mb-0 text-center`}>
                    {item.name1}
                    </p>
                    <p className={`${styles.sectionredtitle} text-center mb-0`}>{item.dept}</p>
                    <p className={`${styles.acheiverrank} text-center`}>{item.cont}</p>
                    
                </div>
                ))}

    
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col pt-3 pb-4 text-center">
            <Link href="/our-achievers" className={`${styles.viewbuttonalink}`}>
              <button type="button" className={`${styles.viewbutton}`}>
                View All
              </button>
            </Link>
          </div>
        </div>
      </div>
        </>
    )
}
export default acheiver;